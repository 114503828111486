import React, {Component} from "react"
import {graphql, navigate, StaticQuery} from 'gatsby';
import Layout from "components/Layout/Layout"
import {Container} from "reactstrap"
import styled from 'styled-components'
import {media} from "utils/Media"
import Footer from "components/Layout/Footer"
import HomeCarousel from "components/Carousel/HomeCarousel"
import PerformanceBar from "components/shared/PerformanceBar"
import HomeTitle from "../components/shared/HomeTitle"
import Img from "gatsby-image/withIEPolyfill";
import {window} from "browser-monads";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Cookies from 'universal-cookie'


const WelcomePage = styled.div`
    position: relative;
    width: 100%;
    
    @media ${media.lg} {
        overflow: auto;
        height: 100vh;
    }
    
    .corona {
        color: white;
        padding: 1rem 0 0;
        text-align: center;
        
        a {
            color: white;
            text-decoration: underline;
        }
    }
   
    .billing {
    margin-left: 10.5%;
        @media ${media.lg} {
            max-width: 80vw;
        }
    }
    
    
    .yellow-bg {
        background-color: ${props => props.theme.colors.yellow};
    }
    
    .topImage {
        padding-top: 85px;
        
        @media ${media.md} {
            padding-top: 51px;
        }
    }
    
    #tickx-widget {
      margin: 3rem auto;
    }
`
const FormBodyStyles = styled.section`
    width:100%;
`

const SignUp = ({ data }) => {
    const headerImage = data.bg
    window.tx({
        widgetId: 'TKaMBlondon',
        configId: '3b435da5',
        type: 'onboarding',
        onDismiss: (obj) => {
            if (obj) {
                const listFields = {
                    'NAME': obj.user.name,
                    'REASON': obj.selected.questions["What is your main reason for seeing ‘To Kill a Mockingbird’?"][0],
                    'GOWITH': obj.selected.questions["Who are you<br />planning to go with?"][0],
                    'MARKETING': typeof obj.selected.terms["I would like to opt-in to hear about productions from the producers of ‘To Kill a Mockingbird’"] !== 'undefined' ? "Yes" : "No"
                }
                // const listFields = {
                //     'NAME': obj.user.name,
                //     'REASON': obj.selected.questions.shows[0],
                //     'GOWITH': obj.selected.questions.who_with[0],
                //     'MARKETING': typeof obj.selected.terms.show !== 'undefined' ? "Yes" : "No"
                // }


                addToMailchimp(obj.user.email, listFields) // listFields are optional if you are only capturing the email address.
                    .then(data => {
                        console.log(data)
                    })
                navigate(
                    "/thank-you/",
                    {
                        state: {
                            thankYou: true,
                            name: obj.user.name
                        },
                    }
                )
            }
        },
        onFormComplete: (user) => {
            window.gtag = window.gtag || [];
            window.gtag('event', 'conversion', {'send_to': 'AW-699463522/Ei_hCILdvrEBEOLuw80C'});
        }
    })
  return (
    <Layout title="Book tickets direct with the official box office." page="index">
                <WelcomePage>
                    <Img fadeIn={true} fluid={headerImage.childImageSharp.fluid}
                         className="topImage d-block pb-5 pb-md-0 mb-4 mb-md-0" alt=""/>
                    <div className="yellow-bg d-none d-md-block">
                        <HomeCarousel/>
                    </div>
                    <HomeTitle/>
                    <PerformanceBar/>
                    <Container fluid={true}>
                        <Container>
                            <Footer/>
                        </Container>
                    </Container>
                </WelcomePage>
            </Layout>
  )
}

export default SignUp





export const SignUpQuery = graphql`
     query {
        bg: file(relativePath: { eq: "top-art.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`